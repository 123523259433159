import IAuthorizationService from '@/data/AuthenticationService.Types';

import AuthorizationService from '@/data/AuthenticationService';
import IDownloadService from './data/DownloadService.Types';
import DownloadService from './data/DownloadService';

export interface IConfiguration {
    CentralServiceAPI: string;
    ApplicationDownloadURL: string;
    CompositionRoot: {
        AuthenticationService: () => IAuthorizationService;
        DownloadService: () => IDownloadService;
    };
}

const configuration: IConfiguration = {
     //CentralServiceAPI: 'https://msbase-dev-02.msbase.radiologics.com',
    CentralServiceAPI: 'https://central.msbir.sydney.edu.au',
    ApplicationDownloadURL: 'https://github.com/Australian-Imaging-Service/msbir-xnat-download-tool',
    CompositionRoot: {
        AuthenticationService: () => new AuthorizationService(configuration.CentralServiceAPI),
        DownloadService: () => new DownloadService(configuration.CentralServiceAPI)
    }
};

export default configuration;
